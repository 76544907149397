import React from "react"
import clsx from "clsx"
import { makeStyles, Typography, withStyles } from "@material-ui/core"
import shallow from "zustand/shallow"
import { useActiveMetric, useOptionsStore } from "../../common/hooks"
import Stack from "../Stack"
import SpikeMarker from "../markers/SpikeMarker"
import { extent } from "d3-array"
import { getDataMetricSelector } from "../../common/utils"
import { formatMetricValue } from "../../common/utils/formatters"
import {
  getColorForJurisdiction,
  getGradientForJurisdiction,
} from "../../common/utils/selectors";
import JurisdictionToggles from "../controls/JurisdictionToggles";

import { getLang } from "../../common/utils/i18n";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  toggleContainer: {
    marginBottom: "-0.25rem",
  },
});

const useSpikeLegendStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

const SpikeLegend = ({ data, soleCategory, group, sizeRange = [1, 60] }) => {
  const classes = useSpikeLegendStyles();
  const [categoryColors, categoryGradients] = useOptionsStore(
    (state) => [state.categoryColors, state.categoryGradients],
    shallow
  );

  const metric = useActiveMetric();

  const accessor = getDataMetricSelector(metric, group);
  const dataExtent = extent(data, accessor);
  const noValuesMapped = !dataExtent[1];
  // check if there is only a single non-zero value
  const singleValueMapped = data.every(
    (d) => !accessor(d) || accessor(d) === dataExtent[1]
  );

  const isRate = metric.indexOf("_rate") > -1;
  const formatId = isRate ? "rate_legend" : "count_legend";
  const spikeLabels = [isRate ? 0 : 1, dataExtent[1] / 2, dataExtent[1]].map(
    (d) => {
      if (!isRate && d < 1) {
        // when max value is 0 or 1
        return "N/A";
      }
      const value = formatMetricValue(d, formatId);
      const parts = value.split(".");
      // if integer with .0 ending, return only the integer part
      return parts.length > 1 && !isRate
        ? parts[1].indexOf("k") > -1
          ? value
          : parts[0]
        : value;
    }
  );

  const spikeStroke = soleCategory
    ? getColorForJurisdiction(soleCategory)
    : categoryColors[categoryColors.length - 1];
  const spikeFill = soleCategory
    ? getGradientForJurisdiction(soleCategory)
    : categoryGradients[categoryGradients.length - 1];
  return (
    <Stack horizontal spacing={1} align="bottom">
      {noValuesMapped ? (
        <Typography variant="body2">
          {getLang("empty_legend_message")}
        </Typography>
      ) : (
        <>
          {!singleValueMapped && (
            <>
              <Stack align="center" spacing={0.5}>
                <SpikeMarker
                  height={sizeRange[0]}
                  width={7}
                  stroke={spikeStroke}
                  fill={spikeFill}
                />
                <Typography className={classes.label} variant="body2">
                  {spikeLabels[0]}
                </Typography>
              </Stack>
              <Stack align="center" spacing={0.5}>
                <SpikeMarker
                  height={sizeRange[1] / 2}
                  width={7}
                  stroke={spikeStroke}
                  fill={spikeFill}
                />
                <Typography className={classes.label} variant="body2">
                  {spikeLabels[1]}
                </Typography>
              </Stack>
            </>
          )}
          <Stack align="center" spacing={0.5}>
            <SpikeMarker
              height={sizeRange[1]}
              width={7}
              stroke={spikeStroke}
              fill={spikeFill}
            />
            <Typography className={classes.label} variant="body2">
              {spikeLabels[2]}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};

const MapLegend = ({
  data,
  classes,
  categories,
  className,
  group = "residents",
  ...props
}) => {
  // the category (jurisdiction) mapped, if only one is (ie ICE, BOP pages)
  const soleCategory = categories && categories.length === 1 && categories[0];

  return (
    <Stack
      horizontal
      className={clsx("map-legend", classes.root, className)}
      align="bottom"
      spacing={2}
      {...props}
    >
      {!soleCategory && (
        <JurisdictionToggles classes={{ root: classes.toggleContainer }} />
      )}
      <SpikeLegend data={data} soleCategory={soleCategory} group={group} />
    </Stack>
  );
};

MapLegend.propTypes = {}

export default withStyles(styles)(MapLegend)
