import React, { useEffect, useState } from "react";
import { Header as BaseHeader } from "@hyperobjekt/material-ui-website";
import { Box, styled, useMediaQuery, useTheme } from "@material-ui/core";
import Container from "gatsby-theme-hypersite/src/container";
import DesktopNavigation from "./desktop-navigation";
import MobileNavigation from "./mobile-navigation";
import Logo from "../logo";
import { GatsbyLink } from "gatsby-material-ui-components";
import MenuIcon from "gatsby-theme-hypersite/src/icons/menu";
import { SkipNavLink } from "@reach/skip-nav";
import SocialLinks from "gatsby-theme-hypersite/src/header/social-links";

import { SeparatorVertIcon } from "../../common/icons/SeparatorVertIcon";
import { withStyles } from "@material-ui/styles";
import SearchButton from "./search-button";
import { useOptionsStore } from "../../common/hooks";
import clsx from "clsx";

const ContentContainer = styled(Container)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const styles = (theme) => ({
  headerWithBanner: theme.overrides.sharedStyles.bannerOffset,
  mobileNav: {
    [`@media (max-width: ${theme.overrides.sharedValues.menuBreakpoint}px)`]: {
      display: "block",
    },
  },
  separator: {
    margin: theme.spacing(0, 1),
  },
});

/**
 *  Basic site header component.  Shows skip nav, logo, and navigation.
 */
const Header = ({
  logo,
  links,
  title,
  social,
  children,
  classes,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(
    `(max-width:${theme.overrides.sharedValues.menuBreakpoint}px)`
  );

  const bannerDismissed = useOptionsStore((state) => state.bannerDismissed);
  return (
    <BaseHeader
      sticky
      stickyOffset={0}
      className={clsx({ [classes.headerWithBanner]: !bannerDismissed })}
      {...props}
    >
      <SkipNavLink />
      <ContentContainer>
        <Logo title={title} logo={logo} />
        <DesktopNavigation
          LinkComponent={GatsbyLink}
          isGatsbyLink={true}
          LinkProps={{
            partiallyActive: true,
          }}
          links={links}
        />
        <Box display="flex" alignItems="center">
          <SearchButton />
          {!isMobile && <SeparatorVertIcon className={classes.separator} />}
          <MobileNavigation
            className={classes.mobileNav}
            buttonLabel={<MenuIcon />}
            links={links}
          />
          <SocialLinks links={social} />
        </Box>

        {children}
      </ContentContainer>
    </BaseHeader>
  );
};

export default withStyles(styles)(Header);
