import React from "react";
import { useLocation, navigate } from "@reach/router";
import { Link } from "gatsby-material-ui-components";
import LeftArrow from "../../common/icons/LeftArrow";
import { Block } from "@hyperobjekt/material-ui-website";

/** No breadcrumb on site, only provide "back to blog" link on blog pages */
const SiteBreadcrumb = (props) => {
  const location = useLocation();
  const isAuthorPage = location.pathname.indexOf("/blog/authors/") > -1;
  const isBlogPage = location.pathname.indexOf("/blog/") > -1;
  return location.pathname.indexOf("/blog/") > -1 ? (
    <Block small>
      {isBlogPage && !isAuthorPage && (
        <Link to="/blog">
          <LeftArrow aria-hidden="true" style={{ marginRight: 8 }} /> Back to
          Blog
        </Link>
      )}
      {isAuthorPage && (
        <Link to="#" onClick={() => navigate(-1)}>
          <LeftArrow aria-hidden="true" style={{ marginRight: 8 }} /> Back
        </Link>
      )}
    </Block>
  ) : null;
};

export default SiteBreadcrumb;
