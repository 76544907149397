import React from "react";
import { Box, Typography, withStyles } from "@material-ui/core";
import clsx from "clsx";
import { sansSerifyTypography } from "../../gatsby-theme-hypercore/theme";

/**
 * Displays a header for tooltips, including a title and optional
 * labels beneath the title, separated by a divider.
 */
const TooltipHeader = withStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    // dotted line with 4px spacing between dots
    backgroundImage: `linear-gradient(to right, white 25%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: `bottom`,
    backgroundSize: `4px 1px`,
    backgroundRepeat: `repeat-x`,
  },
  title: {
    ...sansSerifyTypography,
    fontWeight: 700,
    fontSize: "16px", // fixed size on tooltips
    display: "block",
    color: "#fff",
    marginBottom: theme.spacing(0.25),
  },
  label: {
    fontSize: "14px",
    color: "#fff",
    "&:not(:last-of-type)": {
      borderRight: "1px solid rgba(255, 255, 255, 0.7)",
      paddingRight: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}))(({ title, labels, classes, className, ...props }) => (
  <Box className={clsx(classes.root, className)} {...props}>
    <Typography className={classes.title}>{title}</Typography>
    {Boolean(labels.length) && (
      <Box display="flex">
        {labels.map((label, i) => (
          <Typography key={i} variant="body2" className={classes.label}>
            {label}
          </Typography>
        ))}
      </Box>
    )}
  </Box>
));

export default TooltipHeader;
