import React from "react";

const InfoIcon = (props) => {
  return (
    <svg
      width="21"
      height="26"
      viewBox="0 0 21 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10.5" cy="13.5" r="10.5" fill="#E5EDE0" />
      <path
        d="M10.576 11.784C10.8 11.784 10.9773 11.854 11.108 11.994C11.2387 12.1247 11.304 12.302 11.304 12.526C11.304 12.6847 11.2807 12.8573 11.234 13.044C11.1967 13.2213 11.1547 13.3753 11.108 13.506L10.156 16.264C10.072 16.5067 10.0113 16.6793 9.974 16.782C9.93667 16.8847 9.918 16.978 9.918 17.062C9.918 17.1273 9.932 17.1833 9.96 17.23C9.988 17.2767 10.03 17.3 10.086 17.3C10.3753 17.3 10.7627 16.922 11.248 16.166H11.71C11.3927 16.7727 11.0333 17.2533 10.632 17.608C10.24 17.9627 9.86667 18.14 9.512 18.14C9.29733 18.14 9.12467 18.0607 8.994 17.902C8.87267 17.7433 8.812 17.5333 8.812 17.272C8.812 17.104 8.84933 16.908 8.924 16.684L10.03 13.45C10.114 13.2167 10.156 13.0113 10.156 12.834C10.156 12.6847 10.0907 12.61 9.96 12.61C9.792 12.61 9.61 12.708 9.414 12.904C9.22733 13.1 9.04533 13.3707 8.868 13.716H8.434C8.76067 13.0533 9.09667 12.568 9.442 12.26C9.79667 11.9427 10.1747 11.784 10.576 11.784ZM11.64 8.298C11.8453 8.298 12.0133 8.37267 12.144 8.522C12.284 8.662 12.354 8.82533 12.354 9.012C12.354 9.19867 12.284 9.36667 12.144 9.516C12.0133 9.656 11.8453 9.726 11.64 9.726C11.444 9.726 11.276 9.656 11.136 9.516C10.996 9.376 10.926 9.208 10.926 9.012C10.926 8.816 10.996 8.648 11.136 8.508C11.276 8.368 11.444 8.298 11.64 8.298Z"
        fill="#283224"
      />
    </svg>
  );
};

export default InfoIcon;
