// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-federal-js": () => import("./../../../src/templates/federal.js" /* webpackChunkName: "component---src-templates-federal-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-immigration-js": () => import("./../../../src/templates/immigration.js" /* webpackChunkName: "component---src-templates-immigration-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-states-js": () => import("./../../../src/templates/states.js" /* webpackChunkName: "component---src-templates-states-js" */),
  "component---src-templates-story-index-js": () => import("./../../../src/templates/story-index.js" /* webpackChunkName: "component---src-templates-story-index-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

