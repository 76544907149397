import React from "react";
import { Block } from "@hyperobjekt/material-ui-website";
import Stack from "../Stack";
import {
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import {
  compactTitleTypography,
  serifTypography,
} from "../../gatsby-theme-hypercore/theme";
import { useLatestPosts } from "../../common/hooks";
import { PostTeaser } from "../blog";
import { Link } from "gatsby";
import { ArrowForward } from "@material-ui/icons";

export const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  title: {
    ...compactTitleTypography,
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(32),
    margin: 0,
  },
  titleWrapper: {
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(7),
    borderBottom: "1px solid #E0E0E0",
  },
  titleLink: {
    ...serifTypography,
    fontSize: theme.typography.pxToRem(16),
    position: "relative",
    color: theme.palette.text.primary,
    fontWeight: 400,
    textDecoration: "none !important",
    paddingBottom: theme.spacing(0.5),
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    "&:first-child": {
      marginRight: theme.spacing(4),
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      top: "10px",
      right: "105%",
      width: "12px",
      transform: "translateY(-50%)",
    },
  },
});

const StyledTeaser = withStyles((theme) => ({
  root: {
    paddingBottom: 0,
    "& + $root": {
      paddingTop: 0,
    },
    "&:not(:last-child):before": {
      display: "none",
    },
    "& > a": {
      ...serifTypography,
      fontSize: theme.typography.pxToRem(16),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(34),
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    maxWidth: "32em",
  },
}))(PostTeaser);

const HomeUpdates = ({ lang, classes, ...props }) => {
  const posts = useLatestPosts();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { title, blog_link_text, reports_link_text } = lang;

  return (
    <Block
      bgcolor={process.env.GATSBY_HIDE_TIMESERIES && "background.paper"}
      className={classes.root}
      {...props}
    >
      <Stack
        horizontal="sm"
        spacing={3}
        justify="space-between"
        align="center"
        className={classes.titleWrapper}
      >
        {title && (
          <div>
            <Typography className={classes.title} variant="h3">
              {title}
            </Typography>
          </div>
        )}
        <div>
          {blog_link_text && (
            <Link to="/blog" className={classes.titleLink}>
              <ArrowForward />
              {blog_link_text}
            </Link>
          )}

          {reports_link_text && (
            <Link to="/blog" className={classes.titleLink}>
              <ArrowForward />
              {reports_link_text}
            </Link>
          )}
        </div>
      </Stack>
      <Stack
        horizontal="md"
        spacing={isMobile ? 7 : 7}
        justify="space-between"
        style={{ flex: 1 }}
      >
        {posts.map(({ author, ...p }) => (
          <StyledTeaser {...p} author={[]} key={p.url} />
        ))}
      </Stack>
    </Block>
  );
};

export default withStyles(styles)(HomeUpdates);
