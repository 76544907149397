import { json } from "d3-fetch";
import { useMemo } from "react";
import * as JsSearch from "js-search";
import { useQuery } from "react-query";

const indexedFields = ["authors", "excerpt", "extra", "keywords", "name"];

export default function useSearchData() {
  const { data, isSuccess } = useQuery("search", () => json("/search-1.json"));

  if (isSuccess && !data.items) {
    console.error("search-1.json not found, run gatsby build");
  }

  const search = useMemo(() => {
    if (!data || !data.items) return null;

    data.items.forEach((item, idx) => {
      item.id = idx;
      return item;
    });

    const searchFn = new JsSearch.Search("id"); // url instead?
    indexedFields.forEach((f) => searchFn.addIndex(f));

    searchFn.addDocuments(data.items);

    return searchFn;
  }, [isSuccess, data]);

  return { isSuccess, search };
}
