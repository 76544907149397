import { useStaticQuery, graphql } from "gatsby";

export default function useVaccineData() {
  const {
    allVaccines: { nodes },
  } = useStaticQuery(
    graphql`
      query {
        allVaccines {
          nodes {
            id
            jurisdiction
            isState
            isFederal
            isIce
            residents {
              initiated_pct
              population
            }
            staff {
              initiated_pct
              population
            }
          }
        }
      }
    `
  );

  const filtered = nodes.map((n) => {
    if (n.residents.initiated_pct) {
      n.residents.percentInitiated = n.residents.initiated_pct;
    }
    if (n.staff.initiated_pct) {
      n.staff.percentInitiated = n.staff.initiated_pct;
    }
    return n;
  });

  return filtered;
}
