import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core";
import { sansSerifyTypography } from "../../gatsby-theme-hypercore/theme";

const styles = (theme) => ({
  root: {
    ...sansSerifyTypography,
    width: "auto",
    tableLayout: "auto",
    paddingTop: 0,
    fontSize: "14px",
    color: "#fff",
    "& td:first-child": {
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    "& th, & td": {
      padding: 0,
      margin: 0,
      paddingBottom: 2,
    },
    // no bottom padding on final row
    "& tr:last-child > *": {
      paddingBottom: 0,
    },
    "& .selected": {
      color: theme.palette.secondary.main,
    },
  },
  row: {},
  value: {
    fontWeight: "bold",
  },
  label: {
    fontWeight: "normal",
  },
});

/**
 * Displays a list of stat values and labels side-by-side.
 */
const TooltipStats = withStyles(styles)(({ stats, classes, className }) => {
  return (
    <table className={clsx(classes.root, className)}>
      <tbody>
        {stats.map((stat, i) => {
          return (
            <tr
              className={clsx(classes.row, {
                selected: stat.selected,
              })}
              key={i}
            >
              <td className={classes.value}>{stat.value}</td>
              <td className={classes.label}>{stat.label}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

export default withStyles(styles)(TooltipStats);
