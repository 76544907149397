import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import React from "react";
import { ArrowForward } from "@material-ui/icons";

const MOBILE_EXCERPT_CHAR_LIMIT = 92;

const styles = (theme) => ({
  root: {
    textDecoration: "none",
    borderBottom: "1px solid #F1F1EB",

    "&:hover, &:focus": {
      background: "#F6F6F4",
      textDecoration: "none",
      "& .MuiSvgIcon-root": {
        visibility: "visible",
      },
    },
  },
  container: {
    display: "flex",
    maxWidth: 1300,
    margin: "auto",
  },
  content: {
    flex: "1 1 100%",
    padding: theme.spacing(3, 0, 3, 3),
    [theme.breakpoints.up("md")]: {
      // fontSize: theme.typography.pxToRem(29),
      padding: theme.spacing(7, 0, 6, 7),
      maxWidth: theme.spacing(70),
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      maxWidth: "unset",
    },
  },
  name: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.3,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(29),
      paddingBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.up("lg")]: {
      flex: "1 1",
      paddingBottom: theme.spacing(0),
      marginRight: theme.spacing(6),
    },
  },
  excerpt: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 44%",
    },
  },
  arrowContainer: {
    display: "flex",
    marginLeft: "auto",
    flex: `0 0 ${theme.columnSpacing(2)}`,
    "& .MuiSvgIcon-root": {
      visibility: "hidden",
      fill: theme.palette.secondary.main,
      margin: "auto",
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiSvgIcon-root": {
        margin: theme.spacing(7, 7, 0, "auto"),
      },
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 16%",
    },
  },
});
const SearchResult = ({ result, term, classes }) => {
  let { name, url, excerpt } = result;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile && excerpt && excerpt.length > MOBILE_EXCERPT_CHAR_LIMIT) {
    excerpt = excerpt.slice(0, MOBILE_EXCERPT_CHAR_LIMIT - 3) + "…";
  }

  // highlight words matching the term
  // first remove characters that aren't alhpanumeric, whitespace, or "-"
  const strippedTerm = term.replace(/[^\w\s-]/g, "");
  // use that to create a regex that matches all instances, ignoring case
  const termReg = new RegExp(strippedTerm, "gi");
  // wrap all matches in <mark>
  name = name.replace(termReg, (m) => `<mark>${m}</mark>`);
  excerpt = !excerpt
    ? ""
    : excerpt.replace(termReg, (m) => `<mark>${m}</mark>`);

  const to = url.startsWith("/") ? url : "/" + url;
  return (
    <Link on to={to} className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Typography
            variant="h2"
            className={classes.name}
            dangerouslySetInnerHTML={{ __html: name }}
          />
          <Typography
            variant="h3"
            className={classes.excerpt}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        </Box>
        <Box className={classes.arrowContainer}>
          <ArrowForward />
        </Box>
      </Box>
    </Link>
  );
};

export default withStyles(styles)(SearchResult);
