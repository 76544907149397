import create from "zustand";
import {
  METRICS,
  JURISDICTIONS,
  JURISDICTION_COLORS,
  JURISDICTION_GRADIENTS,
  GROUPS,
} from "../constants";

// grab first group (residents)
const metricGroup = Object.keys(METRICS)[0];

const useOptionsStore = create((set) => ({
  searchActive: false,
  // assume dismissed, then set active if value not found in localStorage
  // (prevents a jarring banner exit)
  bannerDismissed: true,
  metric: METRICS[metricGroup][0],
  metrics: METRICS[metricGroup],
  federalTableGroup: GROUPS[0],
  iceRegionId: null,
  categories: JURISDICTIONS,
  selectedCategories: JURISDICTIONS,
  categoryColors: JURISDICTION_COLORS,
  categoryGradients: JURISDICTION_GRADIENTS,
  setSearchActive: (searchActive) => set({ searchActive }),
  setBannerActive: () => set({ bannerDismissed: false }),
  setBannerDismissed: () => {
    localStorage.setItem("banner-dismissed", true);
    set({ bannerDismissed: true });
  },
  setMetric: (metric) => set({ metric }),
  setFederalTableGroup: (federalTableGroup) => set({ federalTableGroup }),
  setIceRegionId: (iceRegionId) => set({ iceRegionId }),
  setMetrics: (options) => set({ options }),
  setCategories: (categories) => set({ categories }),
  setSelectedCategories: (selectedCategories) => set({ selectedCategories }),
}));

export default useOptionsStore;
