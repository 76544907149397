import { format } from "d3-format";
import { isNumber } from "../../common/utils/selectors";

/** Formatter for integer values */
export const intFormatter = format(",d");

/** Formatter for percent values */
export const perFormatter = format(".0%");

/** Format counts, replace unavailable with "--" */
export const countFormatter = (value) =>
  !isNumber(value) ? "--" : intFormatter(value);

/** Format rates, replace unavailable with "--" */
export const rateFormatter = (value) =>
  !isNumber(value) ? "--" : perFormatter(value);

export const tenKFormatter = (value) =>
  !isNumber(value) ? "--" : intFormatter(value * 10000);

/** Sorter function for numeric values (including rates) */
export const numericSorter = (r1, r2, colId, sortAsc) => {
  const [r1First, r2First] = sortAsc ? [1, -1] : [-1, 1];

  const [v1, v2] = [r1, r2].map((r) => r.values[colId]);
  if (isNumber(v1) && !isNumber(v2)) return r1First;
  if (!isNumber(v1) && isNumber(v2)) return r2First;
  if (!isNumber(v1) && !isNumber(v2)) return 0;
  return v1 - v2;
};
