import React from "react";
import { default as BaseLayout } from "gatsby-theme-hypersite/src/layout";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { SearchContainer } from "../components/search";

const queryClient = new QueryClient();
const Layout = ({ children, ...props }) => {
  return (
    <BaseLayout {...props}>
      <Helmet
        htmlAttributes={{
          lang: props.frontmatter?.lang || "en",
        }}
      >
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cloud.typography.com/6135894/6886832/css/fonts.css"
        />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <SearchContainer />
      </QueryClientProvider>
      {children}
    </BaseLayout>
  );
};

export default Layout;
