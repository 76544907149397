import React from "react";
import GenericSelection from "../controls/GenericSelection";
import shallow from "zustand/shallow";
import { GROUPS } from "../../common/constants";
import { useOptionsStore } from "../../common/hooks";

const GroupSelection = () => {
  const [federalTableGroup, setFederalTableGroup] = useOptionsStore(
    (state) => [state.federalTableGroup, state.setFederalTableGroup],
    shallow
  );

  return (
    <GenericSelection
      options={GROUPS}
      selectedOption={federalTableGroup}
      handleSelect={setFederalTableGroup}
    />
  );
};

export default GroupSelection;
