import React from "react";
import { withStyles, Button } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    border: "none",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    alignSelf: "flex-start",
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    width: "20px",
  },
});

const DownloadDataButton = ({ dataLink, dataLinkText, classes, ...props }) => {
  if (!dataLink) return null;
  return (
    <Button className={classes.root} href={dataLink} target="_blank" {...props}>
      <OpenInNew aria-hidden="true" className={classes.icon} />
      {dataLinkText}
    </Button>
  );
};

export default withStyles(styles)(DownloadDataButton);
