import {
  Box,
  TextField,
  Dialog,
  withStyles,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SearchCloseIcon } from "../../common/icons/SearchCloseIcon";
import React, { useEffect, useRef } from "react";
import { useOptionsStore } from "../../common/hooks";
import SearchResult from "./SearchResult";
import SearchSummary from "./SearchSummary";
import useSearchData from "./useSearchData";
import { useLocation } from "@reach/router";
import clsx from "clsx";

const styles = (theme) => ({
  root: {
    inset: "0 !important",
    zIndex: "1200 !important",
    background: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      zIndex: "100 !important",
      inset: `${theme.layout.headerHeight} 0 0 !important`,
    },
  },
  dialog: {
    width: "100%",
    height: "100%",
    maxHeight: "unset",
    maxWidth: "unset",
    margin: 0,
  },
  closeWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px 14px 0 0",
    "& .MuiButtonBase-root": {
      borderRadius: 24,
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  detailsWrapper: {
    width: "100%",
    maxWidth: 1300,
    margin: "0 auto",
    padding: theme.spacing(1, 3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 7, 7.5),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(3, 7, 11.5),
    },
  },
  searchField: {
    width: "100%",
    "& input": {
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(30),
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.pxToRem(36),
      },
    },
  },
  empty: {},
  clearButton: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(10),

    "&$empty": {
      visibility: "hidden",
    },
  },
});

const MIN_SEARCH_CHARS = 3;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const SearchContainer = ({ classes }) => {
  const [searchActive, setSearchActive] = useOptionsStore((state) => [
    state.searchActive,
    state.setSearchActive,
  ]);

  // close search on page navigation
  // https://carl-topham.com/articles/hooking-gatsby-navigation-changes/
  const location = useLocation();
  const prevLocation = usePrevious(location);
  useEffect(() => {
    if (location !== prevLocation) {
      setSearchActive(false);
    }
  }, [location, prevLocation, setSearchActive]);

  const [searchTerm, setSearchTerm] = React.useState(null);
  const [results, setResults] = React.useState(null);
  const { isSuccess, search } = useSearchData();

  const searching = searchTerm && searchTerm.length >= MIN_SEARCH_CHARS;

  const searchHandler = (e, reset) => {
    const term = reset ? "" : e.target.value;
    setSearchTerm(term);

    if (!term || term.length < MIN_SEARCH_CHARS) {
      setResults(null);
      return;
    }

    if (search) {
      const items = search.search(term);
      setResults(items);
    }
  };

  const handleExit = () => {
    setSearchTerm(null);
    setResults(null);
  };
  const handleClose = () => {
    setSearchActive(false);
  };

  const endAdornment = (
    <IconButton
      aria-label="clear search"
      className={clsx(classes.clearButton, {
        [classes.empty]: !searchTerm,
      })}
      onClick={() => searchHandler(null, true)}
    >
      <CloseIcon />
    </IconButton>
  );

  const placeholder = isSuccess ? "Search by keyword" : "Loading...";
  return (
    <Dialog
      onExit={handleExit}
      classes={{ root: classes.root, paper: classes.dialog }}
      open={searchActive}
    >
      <Box className={classes.closeWrapper}>
        <IconButton onClick={handleClose}>
          <SearchCloseIcon aria-label="close search" />
        </IconButton>
      </Box>
      <Box className={classes.detailsWrapper}>
        <TextField
          autoFocus={true}
          disabled={!isSuccess}
          className={classes.searchField}
          placeholder={placeholder}
          value={searchTerm || ""}
          onChange={searchHandler}
          InputProps={{ endAdornment }}
          inputProps={{ "aria-label": "search site" }}
        />
        <SearchSummary
          searching={searching}
          searchTerm={searchTerm}
          results={results}
        />
      </Box>
      {results &&
        results.map((r, i) => (
          <SearchResult key={(r, i)} result={r} term={searchTerm} />
        ))}
    </Dialog>
  );
};

export default withStyles(styles)(SearchContainer);
