/**
 * we are using a custom implementation with
 * accordion menus for mobile links. This component
 * overrides the default in `gatsby-theme-hypersite`
 * so it returns nothing.
 *
 * See `mobile-navigation.js` the accordion navigation.
 */
const MobileLinks = (props) => {
  return null;
};

export default MobileLinks;
