import React from "react";
import { formatMetricValue } from "../../../common/utils/formatters";
import moment from "moment";
import { Tooltip } from "@visx/xychart";
import useTimeSeriesStore from "../useTimeSeriesStore";
import shallow from "zustand/shallow";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { getLang } from "../../../common/utils/i18n";
import { intFormatter } from "../../table/utils";
import { TOOLTIP_BG_COLOR } from "../../../gatsby-theme-hypercore/theme";
import TooltipHeader from "../../tooltips/TooltipHeader";
import TooltipStats from "../../tooltips/TooltipStats";

const accessors = {
  xAccessor: (d) => new Date(`${d.date}T00:00:00`),
  yAccessor: (d) => Number(d.value),
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: TOOLTIP_BG_COLOR + "!important",
    "& .MuiTypography-root": {
      color: "#fff",
    },
    "& .MuiTypography-body2": {
      fontSize: 14,
    },
  },
}));

const TimeSeriesTooltip = ({ facilitiesById }) => {
  const classes = useStyles();

  const [selectedMetric] = useTimeSeriesStore(
    (state) => [state.selectedMetric],
    shallow
  );

  return (
    <Tooltip
      snapTooltipToDatumX
      snapTooltipToDatumY
      showDatumGlyph
      applyPositionStyle
      className={clsx(classes.root, "ucla-tooltip")}
      renderTooltip={({ tooltipData, colorScale }) => {
        const { datum, distance, key } = tooltipData.nearestDatum;
        // there are no points plotted, so don't show tooltip
        if (distance === Infinity) return null;

        const facility = facilitiesById[key];

        // labels for <TooltipHeader />
        const labels = [];
        facility.state &&
          facility.state !== "*other" &&
          labels.push(facility.state);
        facility.jurisdiction && labels.push(facility.jurisdiction);

        // date label for current point
        const date = moment(accessors.xAccessor(datum).toDateString()).format(
          "MMMM D, YYYY"
        );

        // stat array for <TooltipStats />
        const stats = [
          {
            value: selectedMetric.includes("_rate")
              ? intFormatter(accessors.yAccessor(datum))
              : formatMetricValue(accessors.yAccessor(datum), selectedMetric),
            label: getLang(selectedMetric),
          },
        ];

        return (
          <>
            <TooltipHeader title={facility.name} labels={labels} />
            <Typography gutterBottom variant="body2">
              {date}:
            </Typography>
            <TooltipStats stats={stats} />
          </>
        );
      }}
    />
  );
};

export default TimeSeriesTooltip;
