import React from "react";

export const SeparatorVertIcon = (props) => {
  return (
    <svg
      width="1"
      height="20"
      viewBox="0 0 1 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line x1="0.5" y1="-2.18557e-08" x2="0.500001" y2="20" stroke="#C8C8B9" />
    </svg>
  );
};
