import React from "react"
import clsx from "clsx"
import { fade, Typography, withStyles } from "@material-ui/core"
import MetricSelection from "./MetricSelection"
import RegionSelection from "../immigration/RegionSelection"
import { serifTypography } from "../../gatsby-theme-hypercore/theme"
import { useOptionsStore } from "../../common/hooks"
import GroupSelection from "../federal/GroupSelection";

const styles = (theme) => ({
  root: {
    marginTop: 0,
    "& .MuiButtonBase-root": {
      ...serifTypography,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(26),
      color: theme.palette.secondary.main,
      textTransform: "lowercase",
      border: `2px dotted transparent`,
      borderBottomColor: fade(theme.palette.text.secondary, 0.333),
      borderRadius: 5,
      position: "relative",
      top: "-0.15rem",
    },
  },
});

const MetricSelectionTitle = ({
  classes,
  className,
  title,
  group,
  isFederal,
  isImmigration,
  handleSelection,
  forceSelectedOption = null,
  ...props
}) => {
  const regionSelected = useOptionsStore((state) => state.iceRegionId);

  // inject various selections (eg "${metric}") into title:

  // 1) break title into form:
  // ["Facilities by ", "${metric}", "" in ", "${region}"]
  const titleParts = title.split(/(\$\{\w+\})/g);

  // 2) map placeholders to their components and surrounding text
  // currently GroupSelection is specific to federal table
  /* eslint-disable no-template-curly-in-string */
  const selectionMap = {
    "${group}": [<GroupSelection />],
    "${region}": [
      `${regionSelected ? "the " : ""}`,
      <RegionSelection />,
      ` Field Office region${regionSelected ? "" : "s"}`,
    ],
    "${metric}": [
      <MetricSelection
        group={group}
        isFederal={isFederal}
        isImmigration={isImmigration}
        handleSelection={handleSelection}
        forceSelectedOption={forceSelectedOption}
      />,
    ],
  };
  /* eslint-enable no-template-curly-in-string */

  const titleArray = [];
  // 3) run title parts through the map to replace placeholders with components
  titleParts.forEach((str) => {
    const selectionParts = selectionMap[str];
    if (selectionParts) {
      titleArray.push(...selectionParts);
    } else {
      titleArray.push(str);
    }
  });

  return (
    <Typography
      className={clsx(classes.root, className)}
      variant="h3"
      {...props}
    >
      {titleArray.map((t, i) => (
        <React.Fragment key={i}>{t}</React.Fragment>
      ))}
    </Typography>
  );
};

MetricSelectionTitle.propTypes = {}

export default withStyles(styles)(MetricSelectionTitle)
