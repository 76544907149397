module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/node_modules/gatsby-theme-hypercore/src/templates/page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-external-links"}],"remarkPlugins":[null],"commonmark":true},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-hypercore/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/pages","assetPath":"content/assets","templates":{"default":"/opt/build/repo/src/templates/page.js","home":"/opt/build/repo/src/templates/home.js","immigration":"/opt/build/repo/src/templates/immigration.js","blogIndex":"/opt/build/repo/src/templates/blog-index.js","blog":"/opt/build/repo/src/templates/blog-post.js","author":"/opt/build/repo/src/templates/author.js","storyIndex":"/opt/build/repo/src/templates/story-index.js"}},
    },{
      plugin: require('../node_modules/gatsby-theme-hypersite/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/pages","assetPath":"content/assets","templates":{"default":"/opt/build/repo/src/templates/page.js","home":"/opt/build/repo/src/templates/home.js","immigration":"/opt/build/repo/src/templates/immigration.js","blogIndex":"/opt/build/repo/src/templates/blog-index.js","blog":"/opt/build/repo/src/templates/blog-post.js","author":"/opt/build/repo/src/templates/author.js","storyIndex":"/opt/build/repo/src/templates/story-index.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"uiz8duz"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"COVID Behind Bars","short_name":"COVID Behind Bars","start_url":"/","background_color":"#ffffff","theme_color":"#cccccc","display":"minimal-ui","icon":"content/assets/site-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"576be4f45eb824dfb1e57fcb203dfa74"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"./src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2Z3PLN6H7E"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
