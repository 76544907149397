import React from "react";
import { useVaccineData } from "../../common/hooks";
import { Block } from "@hyperobjekt/material-ui-website";
import { getSlug, isNumber } from "../../common/utils/selectors";
import { Link } from "gatsby-theme-material-ui";
import { DefaultTable } from "../table";
import { Typography, withStyles } from "@material-ui/core";
import FlagIcon from "../../../content/assets/flag-icon.svg";
import IconWithTooltip from "../IconWithTooltip";
import NotesModal from "../NotesModal";
import DownloadDataButton from "../DownloadDataButton";
import { numericSorter, rateFormatter } from "../table/utils";

const alphaStateSort = (r1, r2, colId, sortAsc) => {
  const [r1First, r2First] = sortAsc ? [1, -1] : [-1, 1];
  const [v1, v2] = [r1, r2].map((r) => r.values[colId]);

  // non-state rows (federal, ice) go above states
  if (r1.original.isState !== r2.original.isState) {
    return r1.original.isState ? r1First : r2First;
  }

  return v1 > v2 ? -1 : 1;
};

const styles = (theme) => ({
  root: {
    "& .notes-modal": {
      margin: theme.spacing(3, 0, 2),
    },
  },
  wrapper: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  content: {
    maxWidth: "32rem",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(5),
    },
  },
  body: {
    margin: theme.spacing(2, 0),
  },
  jurisdictionLink: {
    "&.MuiLink-root.MuiTypography-root": {
      color: theme.palette.text.secondary,
    },
  },
  tableWrapper: {
    flex: 1,
    maxWidth: theme.spacing(69),
  },
  table: {
    "& tr $jurisdictionLink:hover": {
      textDecoration: "underline",
      textDecorationThickness: "1px",
      cursor: "pointer",
      textDecorationColor: theme.palette.secondary.main,
    },
    "& .icon-with-tooltip": {
      display: "inline-block",
    },
    "& .icon-wrapper": {
      margin: theme.spacing(0, 0, 0, 1),
      padding: 0,
    },
    // right align headings
    "& .MuiTableCell-head:not(:first-child) .MuiButtonBase-root.MuiTableSortLabel-root":
      {
        textAlign: "right",
      },
  },
});

const VaccineTable = ({ lang, classes, ...props }) => {
  // data for table
  const data = useVaccineData();
  const {
    title,
    subtitle,
    columnHeaders,
    note,
    flag_note: flagNote,
    data_link,
    data_link_text,
  } = lang;

  // column configuration for the table
  const columns = React.useMemo(
    () => [
      {
        id: "jurisdiction",
        Header: columnHeaders["jurisdiction"],
        accessor: "jurisdiction",
        sortType: alphaStateSort,
        Cell: (prop) => {
          const { jurisdiction, isState, isFederal, isIce } = prop.row.original;
          let slug = null;
          if (jurisdiction && isState) {
            slug = `/states/${getSlug(jurisdiction)}`;
          } else if (isFederal) {
            slug = `/federal`;
          } else if (isIce) {
            slug = `/ice`;
          }
          const jurisdictionElement = slug ? (
            <Link to={slug} className={classes.jurisdictionLink}>
              {jurisdiction}
            </Link>
          ) : (
            jurisdiction
          );

          const ent = isFederal ? "federal" : jurisdiction.toLowerCase();
          const flagNoteDetails = flagNote.find(({ entity }) => entity === ent);

          const flagNoteElement = flagNoteDetails ? (
            <IconWithTooltip
              iconText={null}
              title={null}
              icon={FlagIcon}
              notes={[flagNoteDetails.text]}
            />
          ) : null;

          return (
            <Typography variant="body2" color="textSecondary" component="div">
              {jurisdictionElement}
              {flagNoteElement}
            </Typography>
          );
        },
        style: {
          width: "15rem",
        },
      },
      {
        id: "residents.percentInitiated",
        Header: columnHeaders["residents_rate"],
        accessor: "residents.percentInitiated",
        sortType: numericSorter,
        Cell: (prop) => rateFormatter(prop.value),
        style: {
          minWidth: "11.5rem",
          textAlign: "right",
        },
      },
      {
        id: "staff.percentInitiated",
        Header: columnHeaders["staff_rate"],
        accessor: "staff.percentInitiated",
        sortType: numericSorter,
        Cell: (prop) => rateFormatter(prop.value),
        style: {
          minWidth: "9rem",
          textAlign: "right",
        },
      },
    ],
    [classes.jurisdictionLink, columnHeaders, flagNote]
  );

  return (
    <Block
      id="vaccines"
      className={classes.root}
      bgcolor="background.default"
      {...props}
    >
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <Typography variant="h3">{title}</Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            className={classes.body}
          />
          <NotesModal disableNumbering notes={note} />
          {data_link && data_link.length > 0 && (
            <DownloadDataButton
              dataLink={data_link}
              dataLinkText={data_link_text}
            />
          )}
        </div>
        <div className={classes.tableWrapper}>
          <DefaultTable
            className={classes.table}
            data={data}
            columns={columns}
            startDesc={true}
            initialSortColumn={"residents.percentInitiated"}
            disableFilter={true}
            // topLevelHeaders={topLevelHeaders}
          />
        </div>
      </div>
    </Block>
  );
};

export default withStyles(styles)(VaccineTable);
