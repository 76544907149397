import React, { useState, useEffect } from "react";
import { Box, ButtonBase } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Page } from "@hyperobjekt/material-ui-website";
import { useLocation } from "@reach/router";
import clsx from "clsx";
import closeIcon from "../../content/assets/close-icon.svg";
import { useOptionsStore } from "../common/hooks";

/** Takes the page pathname and returns a class name */
function makeClassName(pathname) {
  if (pathname === "") return "home";
  return pathname
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

const styles = (theme) => ({
  pageWithBanner: theme.overrides.sharedStyles.bannerOffset,
  banner: {
    backgroundColor: "#ddeaf3",
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "auto",
    padding: "0 4rem",
    zIndex: 1,
    height: theme.overrides.sharedValues.bannerHeight.md,
    [theme.breakpoints.down("sm")]: {
      height: theme.overrides.sharedValues.bannerHeight.sm,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "start",
      padding: "0 4rem 0 1rem",
      height: theme.overrides.sharedValues.bannerHeight.xs,
    },
  },
  bannerText: {
    fontFamily: "plantin, serif",
    fontWeight: 400,
    margin: 0,
  },
  link: {
    color: "#5a7095",
  },
  closeButton: {
    position: "absolute",
    right: "2rem",
    top: "50%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down("sm")]: {
      right: "1rem",
    },
  },
});

/**
 * Add page level classes to the default page element
 * based on the pathname
 * (e.g. page--home, page--federal, etc.)
 */
const ClassedPage = ({ className, classes, ...props }) => {
  const location = useLocation();
  const pageClass = makeClassName(location.pathname.substr(1));

  const [bannerDismissed, setBannerDismissed, setBannerActive] =
    useOptionsStore((state) => [
      state.bannerDismissed,
      state.setBannerDismissed,
      state.setBannerActive,
    ]);

  useEffect(() => {
    // check localStorage after page has loaded to avoid bugginess
    if (
      typeof localStorage !== "undefined" &&
      !localStorage.getItem("banner-dismissed")
    ) {
      setBannerActive();
    }
  }, []);

  return (
    <>
      <Page
        className={clsx("page", "page--" + pageClass, className, {
          [classes.pageWithBanner]: !bannerDismissed,
        })}
        {...props}
      />
      {!bannerDismissed && (
        <Box className={classes.banner}>
          <p className={classes.bannerText}>
            As of January 18, 2023, we are no longer regularly collecting data
            on COVID. Historical data remains accessible on our website and{" "}
            <a
              href="https://github.com/uclalawcovid19behindbars/data"
              target="_blank"
              className={classes.link}
            >
              GitHub
            </a>
            . We are now collecting carceral mortality data, which can be found{" "}
            <a
              href="https://github.com/uclalawcovid19behindbars/custodial_mortality_project"
              target="_blank"
              className={classes.link}
            >
              here
            </a>
            .
          </p>
          <ButtonBase
            className={classes.closeButton}
            onClick={setBannerDismissed}
          >
            <img alt="..." src={closeIcon} />
          </ButtonBase>
        </Box>
      )}
    </>
  );
};;

export default withStyles(styles)(ClassedPage);
