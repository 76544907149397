import React from "react";
// import useFacilitiesMetadata from "../../../common/hooks/use-facilities-metadata";
import useTimeSeriesStore from "../useTimeSeriesStore";
import GenericSelection from "../../controls/GenericSelection";
import shallow from "zustand/shallow";
import { GROUPS, METRICS } from "../../../common/constants";

const MetricSelect = ({ ...props }) => {
  const [setSelectedMetric, selectedMetric] = useTimeSeriesStore(
    (state) => [state.setSelectedMetric, state.selectedMetric],
    shallow
  );

  // TODO: which options should be available for staff?
  const options = METRICS[GROUPS[0]].filter((opt) => ["test", "death"].every(m => !opt.includes(m)));

  return (
    <GenericSelection
      options={options}
      selectedOption={selectedMetric}
      handleSelect={setSelectedMetric}
      {...props}
    />
  );
};

export default MetricSelect;
