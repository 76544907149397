import { HomeIntro, HomeMap, HomeMapTooltip, HomeTable, VaccineTable, FacilitiesTimeSeries, HomeSponsors, HomeUpdates } from "components/home";
import homeContent from "../../../../content/lang/home.json";
import * as React from 'react';
export default {
  HomeIntro,
  HomeMap,
  HomeMapTooltip,
  HomeTable,
  VaccineTable,
  FacilitiesTimeSeries,
  HomeSponsors,
  HomeUpdates,
  homeContent,
  React
};