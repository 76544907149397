import {
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "gatsby-theme-material-ui";
import React from "react";
import { Stack } from "@hyperobjekt/material-ui-website";

/** A vertical stack with minimal space between items */
const ShortStack = withStyles({
  vertical: {
    "&$betweenSm > * + *": {
      marginTop: "0.25rem",
    },
  },
  betweenSm: {},
})(Stack);

export const TeamMember = withStyles((theme) => ({
  root: {
    paddingBottom: 0,
    paddingTop: 0,
    marginBottom: theme.spacing(2),
  },
  name: {},
  role: {
    "&.MuiTypography-body2": {
      display: "block",
      marginBottom: 0 + "!important",
      paddingRight: "1rem",
    },
  },
  email: {
    "&.MuiLink-root": {
      color: theme.palette.text.secondary,
    },
  },
}))(({ classes, className, name, url, roles, email, ...props }) => {
  // team member name as primary content
  const primary = url ? (
    <Link to={url} target="_blank">
      {name}
    </Link>
  ) : (
    name
  );
  // use stack of roles + email address for secondary content
  const secondary = (roles?.length || email) && (
    <ShortStack direction="vertical" mt={0.5}>
      {roles.map((r) => (
        <Typography component="span" className={classes.role} variant="body2">
          {r}
        </Typography>
      ))}
      {email && (
        <Link
          className={classes.email}
          variant="body2"
          to={`mailto:${email}`}
          target="_blank"
        >
          {email}
        </Link>
      )}
    </ShortStack>
  );
  return (
    <ListItem
      className={clsx(classes.root, className)}
      disableGutters
      {...props}
    >
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
});
