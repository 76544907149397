import React from "react";
import { Table } from "../table";
import { Typography, withStyles, Tooltip } from "@material-ui/core";
import { useFacilitiesData, useOptionsStore } from "../../common/hooks";
import { Block } from "@hyperobjekt/material-ui-website";
import slugify from "slugify";
import { getColorForJurisdiction } from "../../common/utils/selectors";
import shallow from "zustand/shallow";
import { getLang } from "../../common/utils/i18n";
import JurisdictionToggles from "../controls/JurisdictionToggles";
import DotMarker from "../markers/DotMarker";
import MetricSelectionTitle from "../controls/MetricSelectionTitle";

import clsx from "clsx";
import { Link } from "gatsby-theme-material-ui";
import NotesModal from "../NotesModal";
import { countFormatter, numericSorter, tenKFormatter } from "../table/utils";
import DownloadDataButton from "../DownloadDataButton";
import { GROUPS } from "../../common/constants";
const MAX_FACILITY_LENGTH = 60;

const styles = (theme) => ({
  root: {},
  toggleContainer: {
    margin: theme.spacing(2, 0, 1, -0.75),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 0, 0, -0.75),
    },
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
  },
  state: {
    "&.MuiLink-root.MuiTypography-root": {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1),
    },
  },
  table: {
    "& tr $state:hover": {
      textDecoration: "underline",
      textDecorationThickness: "1px",
      cursor: "pointer",
      textDecorationColor: theme.palette.secondary.main,
    },
    // "& .MuiTableRow-root.row--action:hover": {
    //   background: "rgba(0,0,0,0.02)",
    //   "& .MuiTableCell-body.tableCell--active": {
    //     background: "rgba(0,0,0,0)",
    //   },
    // },
    "& .MuiTableRow-head": {
      background: "rgba(230, 230, 222, 0.6)",
      "&.MuiTableRow-topLevel": {
        background: "transparent",
      },
    },
    "& .MuiTableCell-head": {
      padding: theme.spacing(2),
      borderBottom: "none",
      "&.tableCell--active": {
        boxShadow: `inset 0 -4px ${theme.palette.secondary.main}`,
        background: "rgba(240, 240, 234, 0.25)",
      },
      "&.tableCell--sortedCategory": {
        background: "rgba(240, 240, 234, 0.3)",
      },
    },
    "& .MuiTableCell-head .MuiTableSortLabel-root": {
      textAlign: "center",
      justifyContent: "center",
      padding: 0,
    },
    "& .MuiTableCell-head:first-child .MuiTableSortLabel-root": {
      "& .MuiTableSortLabel-icon": {
        marginLeft: 0,
      },
    },
    "& .MuiTableSortLabel-icon": {
      marginLeft: -16,
    },
    // borders on selected category
    "& .MuiTableCell-body.tableCell--sortedCategory": {
      background: "rgba(240, 240, 234, 0.1)",
      borderLeft: "1px solid rgba(230, 230, 222, 0.6)",
    },
    "& .MuiTableCell-body.tableCell--sortedCategory + .MuiTableCell-body.tableCell--sortedCategory":
      {
        borderLeft: "none",
        borderRight: "1px solid rgba(230, 230, 222, 0.6)",
      },
    "& .MuiTableCell-body.tableCell--active": {
      background: "rgba(240, 240, 234, 0.3)",
    },
  },
  notes: {
    listStyle: "none",
    margin: theme.spacing(2, "auto"),
    maxWidth: "24rem",
    "& li": {
      maxWidth: "24rem",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-around",
      maxWidth: "none",
      "& li + li": {
        marginTop: 0,
      },
    },
  },
});

const HomeTable = ({
  lang,
  classes,
  categories,
  selectedRegion,
  isFederal,
  isImmigration,
  ...props
}) => {
  // pull active metric from the store, with setter
  const [metric, setMetric, federalTableGroup] = useOptionsStore(
    (state) => [state.metric, state.setMetric, state.federalTableGroup],
    shallow
  );

  const { title, title_sorted_by_metric, note, data_link, data_link_text } =
    lang;

  // data for table
  const data = useFacilitiesData(categories, selectedRegion);

  // styles for number columns in table
  const numberColStyle = React.useMemo(
    () => ({
      width: "9%",
      minWidth: "6rem",
      textAlign: "center",
    }),

    []
  );

  // column configuration for the table
  const columns = React.useMemo(() => {
    const facilityCol = {
      Header: "Facility",
      id: "name",
      accessor: "name",
      Cell: (prop) => {
        const { state, jurisdiction, name } = prop.row.original;
        let entity = state;
        let link = `/states/${slugify(state, { lower: true })}`;

        if (name.toLowerCase().startsWith("all ice")) {
          entity = "ICE Detention";
          link = "/ice";
        } else if (name.toLowerCase().startsWith("all bop")) {
          entity = "Federal Bureau of Prisons";
          link = "/federal";
        }
        const facility = (
          <Typography className={classes.name} variant="body1">
            {prop.value.length > MAX_FACILITY_LENGTH
              ? prop.value.substring(0, MAX_FACILITY_LENGTH) + "..."
              : prop.value}
          </Typography>
        );
        const rowName =
          prop.value.length > MAX_FACILITY_LENGTH ? (
            <Tooltip title={prop.value} arrow placement="top">
              {facility}
            </Tooltip>
          ) : (
            facility
          );
        return (
          <>
            {rowName}
            <Typography variant="body2" color="textSecondary">
              <Link to={link} className={classes.state}>
                {entity}
              </Link>
              <DotMarker
                radius={4}
                fill={getColorForJurisdiction(jurisdiction)}
              />
            </Typography>
          </>
        );
      },
      style: {
        maxWidth: "10.5rem",
      },
    };

    const colMetrics = [
      "confirmed",
      "confirmed_rate",
      "active",
      "active_rate",
      "deaths",
      "deaths_rate",
    ];
    if (!isImmigration && !isFederal) {
      colMetrics.push("tested", "tested_rate");
    }

    const cols = colMetrics.map((colMetric) => {
      const group = isFederal ? federalTableGroup : GROUPS[0];
      const col = {
        id: colMetric,
        Header: getLang(colMetric.indexOf("rate") > -1 ? "rate" : "count"),
        accessor: `${group}.${colMetric}`,
        Cell: (prop) => countFormatter(prop.value),
        sortType: numericSorter,
        style: numberColStyle,
      };

      const isRate = colMetric.indexOf("_rate") > 0;
      if (isRate) {
        col.Cell = (prop) => tenKFormatter(prop.value);
      }

      return col;
    });

    // 503: include hidden column so that table will include state name when filtering
    const stateCol = {
      id: "state",
      Header: "State",
      accessor: "state",
      Cell: (prop) => prop.value,
      style: { display: "none" },
    };

    return [facilityCol, stateCol, ...cols];
  }, [
    classes.name,
    classes.state,
    isImmigration,
    isFederal,
    numberColStyle,
    federalTableGroup,
  ]);

  const [sortCol, setSortCol] = React.useState(metric);
  const [sortedByMetric, setSortedByMetric] = React.useState(true);
  const [sortDesc, setSortDesc] = React.useState(true);

  // memoized table options
  const options = React.useMemo(
    () => ({
      initialState: {
        pageSize: 5,
        sortBy: [{ id: sortCol, desc: sortDesc }],
      },
    }),
    [sortDesc, sortCol]
  );

  const topLevelHeaders = [
    { id: "", text: "", align: "left", colSpan: 1 },
    {
      id: "confirmed",
      text: getLang("confirmed"),
      align: "center",
      colSpan: 2,
    },
    { id: "active", text: getLang("active"), align: "center", colSpan: 2 },
    { id: "deaths", text: getLang("deaths"), align: "center", colSpan: 2 },
  ];
  if (!isImmigration && !isFederal)
    topLevelHeaders.push({
      id: "tested",
      text: getLang("tested"),
      align: "center",
      colSpan: 2,
    });

  // handler for when table headers are clicked
  const handleSortChange = React.useCallback(
    (sortBy) => {
      const isMetric = sortBy !== "name";
      setSortedByMetric(isMetric);
      setSortCol(sortBy);
      if (!isMetric) {
        setSortDesc(!sortDesc);
        return;
      }
      const newMetric = sortBy;
      metric !== newMetric && setMetric(newMetric);
      setSortDesc(true);
    },
    [metric, setMetric, sortDesc]
  );

  // if user selects metric via dropdown, update sort col
  React.useEffect(() => {
    setSortCol(metric);
    setSortedByMetric(true);
  }, [metric]);

  // otherwise column won't update if table sorted by name and active metric is (re)selected
  const handleSelection = (metric) => {
    setSortedByMetric(true);
    setSortCol(metric);
  };

  return (
    <Block className={clsx(classes.root, "home-table")} {...props}>
      <MetricSelectionTitle
        title={sortedByMetric ? title_sorted_by_metric : title}
        isImmigration={isImmigration}
        isFederal={isFederal}
        handleSelection={handleSelection}
        forceSelectedOption={!sortedByMetric && sortCol}
      />
      <Table
        className={classes.table}
        data={data.filter((d) => d.name !== "Statewide")}
        topLevelHeaders={topLevelHeaders}
        columns={columns}
        options={options}
        // sortColumn={sortCol}
        // sortDesc={sortedByMetric}
        onSort={handleSortChange}
      >
        {!isImmigration && !isFederal && (
          <JurisdictionToggles
            marker="dot"
            horizontal="md"
            classes={{ root: classes.toggleContainer }}
          />
        )}
      </Table>
      <div style={{ display: "flex" }}>
        {note && note.length > 0 && <NotesModal notes={note} />}
        {data_link && data_link.length > 0 && (
          <DownloadDataButton
            dataLink={data_link}
            dataLinkText={data_link_text}
          />
        )}
      </div>
    </Block>
  );
};

export default withStyles(styles)(HomeTable);
