import { Box, withStyles } from "@material-ui/core";
import React from "react";

const styles = (theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    // padding: theme.spacing(2, 3, 7.5),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(3),
    },
  },
});

const SearchContainer = ({ searching, searchTerm, results, classes }) => {
  const content = !searching
    ? "Type to search"
    : results && results.length
    ? `Showing ${results.length} results for "${searchTerm}"`
    : "No results";

  return <Box className={classes.root}>{content}</Box>;
};

export default withStyles(styles)(SearchContainer);
