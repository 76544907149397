import React from "react";

const MenuIcon = (props) => {
  return (
    <svg
      width="81"
      height="33"
      viewBox="0 0 81 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="81" height="33" rx="16.5" fillOpacity="0.5" />
      <path
        d="M32.6127 21V10.99H33.8867L37.3447 19.726H37.3727L40.7467 10.99H42.0627V21H41.1527V12.278H41.1247C40.9473 12.81 40.7887 13.2627 40.6487 13.636L37.7507 21H36.9247L33.9987 13.636L33.5087 12.278H33.4807V21H32.6127ZM47.099 21.182C46.0443 21.182 45.2183 20.832 44.621 20.132C44.0236 19.4227 43.725 18.508 43.725 17.388C43.725 16.2867 44.0283 15.3813 44.635 14.672C45.2416 13.9533 46.0396 13.594 47.029 13.594C47.589 13.594 48.0743 13.7013 48.485 13.916C48.905 14.1307 49.2316 14.4293 49.465 14.812C49.6983 15.1947 49.8663 15.6147 49.969 16.072C50.081 16.52 50.137 17.01 50.137 17.542H44.621C44.6396 18.4007 44.859 19.1007 45.279 19.642C45.699 20.174 46.3056 20.44 47.099 20.44C48.2003 20.44 48.905 19.8987 49.213 18.816H50.053C49.8943 19.544 49.5583 20.1227 49.045 20.552C48.541 20.972 47.8923 21.182 47.099 21.182ZM44.635 16.856H49.227C49.2083 16.1 49.0123 15.4887 48.639 15.022C48.275 14.546 47.7383 14.308 47.029 14.308C46.329 14.308 45.7736 14.5413 45.363 15.008C44.9616 15.4747 44.719 16.0907 44.635 16.856ZM51.6323 21V13.762H52.5003V14.868H52.5283C53.0977 14.028 53.905 13.608 54.9503 13.608C55.641 13.608 56.1963 13.8087 56.6163 14.21C57.0457 14.602 57.2603 15.1527 57.2603 15.862V21H56.3923V15.946C56.3923 14.882 55.8417 14.35 54.7403 14.35C54.115 14.35 53.583 14.546 53.1443 14.938C52.715 15.33 52.5003 15.82 52.5003 16.408V21H51.6323ZM61.4037 21.168C60.6943 21.168 60.1203 20.9673 59.6817 20.566C59.243 20.1553 59.0237 19.5907 59.0237 18.872V13.762H59.8917V18.816C59.8917 19.348 60.041 19.754 60.3397 20.034C60.6383 20.3047 61.0583 20.44 61.5997 20.44C62.2157 20.44 62.7197 20.2347 63.1117 19.824C63.5037 19.4133 63.6997 18.9 63.6997 18.284V13.762H64.5537V21H63.6997V19.936H63.6717C63.3823 20.328 63.065 20.6313 62.7197 20.846C62.3743 21.0607 61.9357 21.168 61.4037 21.168Z"
        fill="#283224"
      />
      <line x1="18" y1="20.5" x2="28" y2="20.5" stroke="#283224" />
      <line x1="22" y1="16.5" x2="28" y2="16.5" stroke="#283224" />
      <line x1="16" y1="12.5" x2="28" y2="12.5" stroke="#283224" />
    </svg>
  );
};

export default MenuIcon;
