import React from "react";
// import useFacilitiesMetadata from "../../../common/hooks/use-facilities-metadata";
import useTimeSeriesStore from "../useTimeSeriesStore";
import GenericSelection from "../../controls/GenericSelection";
import shallow from "zustand/shallow";
import { GROUPS } from "../../../common/constants";

const GroupSelect = ({ ...props }) => {
  const [setSelectedGroup, selectedGroup] = useTimeSeriesStore(
    (state) => [state.setSelectedGroup, state.selectedGroup],
    shallow
  );

  return (
    <>
      <GenericSelection
        options={GROUPS}
        selectedOption={selectedGroup}
        handleSelect={setSelectedGroup}
      />
      <br />
    </>
  );
};

export default GroupSelect;
