import React from "react";

export const SearchCloseIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(4 -0.5)">
        <rect
          x="11"
          y="3.71094"
          width="14.5736"
          height="0.97157"
          transform="rotate(135 11 3.71094)"
          fill="#283224"
        />
        <rect
          x="0.687012"
          y="3"
          width="14.5736"
          height="0.97157"
          transform="rotate(45 0.687012 3)"
          fill="#283224"
        />
      </g>
    </svg>
  );
};
