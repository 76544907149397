import React from "react";
import clsx from "clsx";
import { Box, Typography, withStyles } from "@material-ui/core";
import NumberStat from "../stats/NumberStat";
import useToplineData from "../../common/hooks/use-topline-data";
import NotesModal from "../NotesModal";

const styles = (theme) => ({
  root: {
    maxWidth: "max-content",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
      marginTop: theme.spacing(8),
    },
  },
  title: {
    maxWidth: theme.spacing(38),
    marginBottom: theme.spacing(2),
  },
  statContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    maxWidth: theme.spacing(48),

    "& .number-stat-label": {
      fontSize: theme.typography.pxToRem(12),
    },
    "& .number-stat-number": {
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.pxToRem(40),
      },
    },
  },
  staff: {
    "& .number-stat-number": {
      color: "#8D8006",
    },
  },
  stat: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(19),
    padding: 0,
    "&:nth-child(odd)": {
      marginRight: theme.spacing(4),
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(6),
      },
    },
  },
});

const ToplineMetrics = ({ classes, lang }) => {
  const { title, labels, notes } = lang;

  const data = useToplineData();
  return (
    <Box className={classes.root}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      <Box className={classes.statContainer}>
        {data.map(({ id, count }) => (
          <NumberStat
            key={id}
            className={clsx(classes.stat, {
              [classes.staff]: id.includes("staff"),
            })}
            value={count}
            label={labels[id]}
          />
        ))}
      </Box>
      <NotesModal notes={notes} />
    </Box>
  );
};

export default withStyles(styles)(ToplineMetrics);
