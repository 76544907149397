import React from "react";

export const SearchIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(0 -1)">
        <path
          d="M13.6333 7.94475C13.6333 10.9423 11.1507 13.3895 8.06667 13.3895C4.98266 13.3895 2.5 10.9423 2.5 7.94475C2.5 4.94722 4.98266 2.5 8.06667 2.5C11.1507 2.5 13.6333 4.94722 13.6333 7.94475Z"
          stroke="#283224"
        />
        <line
          x1="11.6868"
          y1="11.707"
          x2="14.6868"
          y2="14.707"
          stroke="#283224"
        />
      </g>
    </svg>
  );
};
