import React from "react";
import ReactTooltip from "react-tooltip";
import { TOOLTIP_BG_COLOR } from "../gatsby-theme-hypercore/theme";

/**
 * Tooltip component used for maps
 * See theme.js for style overrides using `ucla-tooltip` class
 */
const Tooltip = (props) => {
  return (
    <ReactTooltip
      type="dark"
      backgroundColor={TOOLTIP_BG_COLOR}
      className="ucla-tooltip"
      place="left"
      // offset={{ top: -15 }}
      {...props}
    />
  );
};

export default Tooltip;
