import { useStaticQuery, graphql } from "gatsby";

export default function useToplineData() {
  const { allToplineMetrics } = useStaticQuery(
    graphql`
      query {
        allToplineMetrics {
          nodes {
            count
            measure
          }
        }
      }
    `
  );

  const groups = ["residents", "staff"];
  // also contains "tadmin"
  const metrics = ["confirmed", "deaths"];
  const results = [];
  groups.forEach((group) => {
    metrics.forEach((metric) => {
      const id = `${group}.${metric}`;
      const row =
        allToplineMetrics.nodes.find((r) => r.measure.toLowerCase() === id) ||
        {};

      results.push({ id, count: row.count });
    });
  });
  return results;
}
