import React from "react";
import useTimeSeriesData from "../../../common/hooks/use-time-series-data";
import {
  formatFacilityName,
  getFacilityColor,
} from "../../../common/utils/formatters";
import moment from "moment";
import { Axis, Grid, XYChart, buildChartTheme } from "@visx/xychart";
import useTimeSeriesStore from "../useTimeSeriesStore";
import shallow from "zustand/shallow";
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
} from "@material-ui/core";
import { sansSerifyTypography } from "../../../gatsby-theme-hypercore/theme";
import TimeSeriesAnnotations from "./TimeSeriesAnnotations";
import { getStateCodeByName } from "../../../common/utils/selectors";
import { getLang } from "../../../common/utils/i18n";
import TimeSeriesLines from "./TimeSeriesLines";
import TimeSeriesTooltip from "./TimeSeriesTooltip";

const dummyData = {
  id: null,
  lineData: [
    {
      date: "",
      value: null,
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...sansSerifyTypography,
  },
  lineInactive: {
    opacity: 0.2,
  },
  lineActive: {
    opacity: 1,
  },
  line: {
    opacity: 1,
  },
  notes: {
    color: "#5d0e06",
    background: "#fbeeee",
    maxWidth: "36em",
    padding: theme.spacing(1.5, 2),
    margin: "auto",
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up("lg")]: {
      margin: `1rem 0`,
      maxWidth: "28em",
    },
  },
}));

const TimeSeriesChart = ({ lang }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const facilitiesData = useTimeSeriesData();

  const [activeLine, setActiveLine] = React.useState(null);

  const [selectedGroup, selectedMetric] = useTimeSeriesStore(
    (state) => [state.selectedGroup, state.selectedMetric],
    shallow
  );

  const accessor = selectedGroup + "_" + selectedMetric;

  const unavailableFacilities =
    facilitiesData
      .filter((f) => !f[accessor] || f[accessor].length < 1)
      .map(({ name, state }) => {
        const stateCode = getStateCodeByName(state);
        return stateCode ? `${name} (${stateCode})` : name;
      }) || [];

  const unavailableText =
    unavailableFacilities.length > 0
      ? lang.unavailable_data_caption
          .replace("${metric}", getLang(selectedMetric).toLowerCase())
          .replace("${group}", getLang(selectedGroup).toLowerCase())
          .replace("${facilities}", unavailableFacilities.join(", "))
          .replace(/^\w/, (l) => l.toUpperCase()) // capitalize first letter
      : "";

  const linesData =
    facilitiesData.length > 0
      ? facilitiesData.map((facilityData) => {
          const lineData =
            facilityData[accessor] && facilityData[accessor].length > 0
              ? facilityData[accessor]
              : dummyData.lineData;
          const { id } = facilityData;
          const name = formatFacilityName(facilityData);
          const lastDatum = lineData.reduceRight((accum, datum) => {
            if (accum) return accum;
            if (!!datum.value && datum.value !== "NaN") return datum;
          }, null);
          return { name, id, lineData, lastDatum };
        })
      : [dummyData];

  const maxAnnotationWidth = isMobile ? 19 : 34;
  const facilitiesById = {};
  facilitiesData.forEach(({ id, ...facilityData }, i) => {
    facilitiesById[id] = facilityData;
    facilitiesById[id].color = getFacilityColor(i);
    const labelArr = [
      facilityData.name,
      facilityData.state === "*other"
        ? ""
        : ", " + getStateCodeByName(facilityData.state),
    ];
    facilitiesById[id].truncatedLabel =
      labelArr.join("").length > maxAnnotationWidth
        ? labelArr[0]
            .slice(0, maxAnnotationWidth - labelArr[1].length - 3)
            .trim() +
          "..." +
          labelArr[1]
        : labelArr.join("");
  });

  const colors = Object.keys(facilitiesById)
    .sort((a, b) => Number(a) - Number(b))
    .map((id) => facilitiesById[id].color);

  const customTheme = buildChartTheme({
    colors,
    xAxisLineStyles: { stroke: theme.palette.text.secondary },
    xTickLineStyles: { stroke: theme.palette.text.secondary },
  });

  const formatDate = (date, i, allTicks) => {
    const justMonth = "MMM";
    const monthAndYear = "MMM 'YY";

    const d = moment(date);
    // first date
    if (!i) {
      const format = monthAndYear;
      return d.format(format);
    } else {
      const previousD = moment(allTicks[i - 1].value);
      // show year only if this tick is in the next year
      const format = d.year() === previousD.year() ? justMonth : monthAndYear;
      return d.format(format);
    }
  };

  const handlePointerMove = (props) => {
    if (props.key && activeLine !== props.key) {
      setActiveLine(props.key);
    }
  };

  const handlePointerOut = () => {
    setActiveLine(null);
  };

  return (
    <>
      <XYChart
        height={400}
        margin={{ top: 55, right: isMobile ? 120 : 197, bottom: 75, left: 65 }}
        xScale={{ type: "time" }}
        yScale={{ type: "linear" }}
        theme={customTheme}
        onPointerMove={handlePointerMove}
        onPointerOut={handlePointerOut}
      >
        <Axis
          tickFormat={formatDate}
          orientation="bottom"
          top={350}
          strokeWidth={2}
          labelOffset={25}
          numTicks={isMobile ? 5 : 10}
          labelProps={{
            style: {
              fontSize: "14px",
              fontWeight: 300,
              color: theme.palette.text.secondary,
              fill: theme.palette.text.secondary,
            },
          }}
          tickLabelProps={() => ({
            style: {
              fontSize: "14px",
              fontWeight: 300,
              color: theme.palette.text.secondary,
              fill: theme.palette.text.secondary,
            },
          })}
        />
        <Axis
          orientation="left"
          left={25}
          numTicks={5}
          labelOffset={5}
          hideAxisLine
          hideTicks
          label={getLang(selectedMetric)}
          labelProps={{
            style: {
              fontSize: "14px",
              fontWeight: 300,
              color: theme.palette.text.secondary,
              fill: theme.palette.text.secondary,
            },
            textAnchor: "middle",
          }}
          tickLabelProps={() => ({
            dx: 10,
            dy: -5,
            textAnchor: "start",
            verticalAnchor: "end",
            style: {
              fontSize: "14px",
              fontWeight: 300,
              color: theme.palette.text.secondary,
              fill: theme.palette.text.secondary,
            },
          })}
        />
        <Grid columns={false} numTicks={5} stroke="#E0E0E0" />
        <TimeSeriesLines linesData={linesData} />
        <TimeSeriesAnnotations
          linesData={linesData}
          facilitiesById={facilitiesById}
        />
        <TimeSeriesTooltip facilitiesById={facilitiesById} />
      </XYChart>
      {unavailableText.length > 0 && (
        <Typography variant="caption" component="p" className={classes.notes}>
          {unavailableText}
        </Typography>
      )}
    </>
  );
};

export default TimeSeriesChart;
