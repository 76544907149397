import React from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import shallow from "zustand/shallow";
import { SearchCloseIcon } from "../../common/icons/SearchCloseIcon";
import { SearchIcon } from "../../common/icons/SearchIcon";
import useOptionsStore from "../../common/hooks/use-options-store";
import { withStyles } from "@material-ui/styles";

const StyledButton = withStyles((theme) => ({
  root: {
    border: "none",
    padding: theme.spacing(0.5, 1),
    fontSize: "0.875rem",
    borderRadius: 24,
    color: theme.palette.text.primary,
    minWidth: 24,
    "& svg": {
      marginRight: theme.spacing(1),
    },
    // hide the "search" text on mobile
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5, 0.5),
      "& .search__text": { display: "none" },
    },
    // fixed button width on larger displays
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.typography.pxToRem(-96),
      width: theme.typography.pxToRem(96),
      "&.active": {
        // marginLeft: theme.typography.pxToRem(-96),
        width: theme.typography.pxToRem(128),
        marginLeft: 0,
      },
    },
  },
}))(Button);

const SearchButton = (props) => {
  const [searchActive, setSearchActive] = useOptionsStore(
    (state) => [state.searchActive, state.setSearchActive],
    shallow
  );
  const handleSearchClick = () => setSearchActive(!searchActive);
  return (
    <StyledButton
      className={clsx({ active: searchActive })}
      variant="text"
      onClick={handleSearchClick}
      {...props}
    >
      {searchActive && (
        <>
          <SearchCloseIcon /> <strong>Close Search</strong>
        </>
      )}
      {!searchActive && (
        <>
          <SearchIcon /> <span className="search__text">Search</span>
        </>
      )}
    </StyledButton>
  );
};

SearchButton.propTypes = {};

export default SearchButton;
