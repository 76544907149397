import React from "react"
import clsx from "clsx";
import { fade, makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import SearchIcon from "@material-ui/icons/Search"
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  empty: {},
  closeButton: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(10),

    "&$empty": {
      visibility: "hidden",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
}));

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const classes = useStyles()
  const count = preGlobalFilteredRows.length

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.

  return (
    <TextField
      value={globalFilter || ""}
      // Set undefined to remove the filter entirely
      onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      placeholder={`${count} records...`}
      classes={{
        root: classes.inputRoot,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            aria-label="close"
            className={clsx(classes.closeButton, {
              [classes.empty]: !globalFilter,
            })}
            onClick={() => setGlobalFilter(undefined)}
          >
            <CloseIcon />
          </IconButton>
        ),
      }}
      inputProps={{ "aria-label": "search" }}
    />
  );
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
}

export default GlobalFilter
