import { useStaticQuery, graphql } from "gatsby";
import { postDataToProps } from "../../components/blog";

export default function useLatestPosts() {
  const { allMdx } = useStaticQuery(
    graphql`
      query MyQuery {
        allMdx(
          filter: { frontmatter: { category: { in: ["blog", "report"] } } }
          sort: { order: DESC, fields: frontmatter___date }
          limit: 3
        ) {
          nodes {
            id
            slug
            frontmatter {
              meta {
                author
              }
              featured
              description
              name
              path
              date
            }
          }
        }
      }
    `
  );

  if (!allMdx || !allMdx.nodes) return [];
  return allMdx.nodes.map(postDataToProps);
}
